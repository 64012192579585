@import '../../styles/breakpoints';

.avatar {
  width: 48px;
  height: 48px;
  align-self: flex-start;

  @include device-small {
    width: 72px;
    height: 72px;
  }
}

.progress {
  width: 100%;
  display: block;
  margin-bottom: var(--size-spacing-02);
}

.memberListItem {
  border-bottom: 1px solid var(--color-neutral-base);
  display: grid;

  &:last-of-type {
    border-bottom: none;
  }

  @include device-small {
    grid-template-columns: minmax(auto, 456px) auto;
  }
}

.ownerName {
  font-size: var(--font-size-02);
}

.donateLinkWrapper {
  margin-bottom: var(--size-spacing-05);
  line-height: var(--font-size-05);

  @include device-small {
    padding-top: var(--size-spacing-04);
    text-align: end;
  }
}

.captainBadge {
  background-color: var(--color-neutral-base);
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.closeButton {
  padding: 0;
  min-width: auto;
  height: auto;
  display: flex;

  svg {
    fill: var(--color-text-lighter);
    height: 24px;
    width: 24px;
  }
}
